<template>
    <section>
        <!-- Recuperar Contraseña -->
        <ValidationObserver ref="validacion" v-slot="{ invalid }">
            <modal
            ref="RecuperarContrasena"
            titulo="Recuperar contraseña"
            icon="password"
            adicional="Generar código"
            no-aceptar
            @adicional="generarCodigoRecuperacion(invalid)"
            >
                <div class="row mx-0 text-muted2 f-15 text-center px-5 mb-5">
                    Introduce el correo electrónico o el número de teléfono para buscar tu cuenta.
                </div>
                <div class="row justify-center mb-5 mt-3">
                    <div class="position-relative" style="width:349px;">
                        <p class="text-muted2 f-600 f-15 px-3">
                            Correo electrónico o teléfono
                        </p>
                        <div v-if="errores.error == 'UT001'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                            <p class="text-general-red f-30 f-600 mt-3 text-center">
                                Espera
                            </p>
                            <p class="text-general f-15 mt-1 f-500 text-center">
                                Estás intentando ingresar como un administrador.
                            </p>
                            <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.admin'})">
                                Ir a la aplicación de administrador
                            </p>
                        </div>
                        <div v-else-if="errores.error == 'UT002'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                            <p class="text-general-red f-30 f-600 mt-3 text-center">
                                Espera
                            </p>
                            <p class="text-general f-15 mt-1 f-500 text-center">
                                Estás intentando ingresar como un {{$config.vendedor}}.
                            </p>
                            <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.leechero'})">
                                Ir a la aplicación de {{$config.vendedor}}
                            </p>
                        </div>
                        <div v-else-if="errores.error == 'UT003'" class="position-absolute alert-message bg-white br-12" style="left:-360px;top:-45px;">
                            <p class="text-general-red f-30 f-600 mt-3 text-center">
                                Espera
                            </p>
                            <p class="text-general f-15 mt-1 f-500 text-center">
                                Estás intentando ingresar como un cliente.
                            </p>
                            <p class="text-green cr-pointer f-15 mt-1 f-500 text-center" @click="$router.push({name: 'login.form.cliente'})">
                                Ir a la aplicación de cliente
                            </p>
                        </div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:500" name="usuario">
                            <el-input v-model="payload.usuario" class="w-100" placeholder="correo@ejemplo.com">
                                <i
                                v-if="errores.noExisteUsuario || errors[0]"
                                slot="suffix"
                                style="color:#FF3333;padding-top:10px;"
                                class="icon-attention-alt f-20 d-flex"
                                />
                            </el-input>
                        </ValidationProvider>
                    </div>
                </div>
            </modal>
        </ValidationObserver>
        <!-- Recuperar Contraseña -->
        <!-- Codigo Contraseña -->
        <modal
        ref="CodigoSeguridad"
        titulo="Código Seguridad"
        icon="login (2)"
        adicional="Validar"
        no-aceptar
        @adicional="validarCodigoRecuperacion"
        >
            <div class="row mx-0 my-2 text-center text-muted2">
                Introduce el código que se te envió al correo electrónico o al número de teléfono registrado.
            </div>
            <div class="row mx-0 mt-3 text-center text-muted2 justify-center ">
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod1"
                    v-model="payload.codigo[0]"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(2)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod2"
                    v-model="payload.codigo[1]"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(3)"
                    @keyup.native.delete="back(1)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod3"
                    v-model="payload.codigo[2]"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(4)"
                    @keyup.native.delete="back(2)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod4"
                    v-model="payload.codigo[3]"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(5)"
                    @keyup.native.delete="back(3)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod5"
                    v-model="payload.codigo[4]"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @keyup.native.delete="back(4)"
                    />
                </div>
            </div>
            <div class="row mt-2 px-5">
                <div v-if="errores.codigo" class="col mb-3 f-12 pl-5" style="color:#FF3333">
                    <p>
                        El código ingresado no es válido
                    </p>
                </div>
            </div>
            <div class="row justify-center ">
                <div class="col f-15" style="color:#0892DD;" @click="generarCodigoRecuperacion(false, false)">
                    <p class="text-center cr-pointer">
                        <template v-if="!timeOut">
                            Volver a enviar el código
                        </template>
                        <template v-else>
                            {{ segundos }} segundos
                        </template>
                    </p>
                </div>
            </div>
        </modal>
        <!-- Codigo Contraseña -->
        <!-- Nueva Contraseña -->
        <ValidationObserver ref="validadorContrasena" v-slot="{ invalid }">
            <modal
            ref="NuevaContrasena"
            titulo="Nueva Contraseña"
            icon="password (1)"
            adicional="Recuperar"
            no-aceptar
            @adicional="restablecerContrasena(invalid)"
            >
                <div class="row mx-0 text-center text-muted2">
                    Crea una nueva contraseña, recuerda que las contraseñas fuertes incluyen números, letras y signos de puntuación
                </div>
                <div class="row mx-0 justify-center my-3">
                    <div style="width:273px;">
                        <p class="f-15 text-muted2">
                            Contraseña
                        </p>
                        <ValidationProvider v-slot="{ errors }" :rules="reglas.contrasena" vid="password" name="contraseña">
                            <el-input
                            v-model="payload.password"
                            class="w-100"
                            placeholder="Nueva contraseña"
                            maxlength="200"
                            minlength="8"
                            show-password
                            />
                            <span class="text-danger f-11" v-text="errors[0]" />
                            <p class="text-muted f-12 mt-2">La contraseña debe tener mínimo 8 cacacteres y contener al menos 1 letra</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 justify-center my-3">
                    <div style="width:273px;">
                        <p class="f-15 text-muted2">
                            Confirmar Contraseña
                        </p>
                        <ValidationProvider v-slot="{ errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                            <el-input
                            v-model="payload.validacionPassword"
                            class="w-100"
                            placeholder="Repita su contraseña"
                            show-password
                            />
                            <span class="text-danger f-11" v-text="errors[0]" />
                        </ValidationProvider>
                    </div>
                </div>
            </modal>
        </ValidationObserver>
        <!-- Nueva Contrasena -->
    </section>
</template>
<script>
import ServicioApi from '~/services/auth';

const segundosDefault = 90
export default {
    props: {
        usuario: {
            type: String,
            default: ''
        },
        tipoLogin: {
            type: Number,
            default: 1
        },
    },
    data(){
        return{
            payload: {
                usuario: '',
                codigo: [],
                password: '',
                validacionPassword: ''
            },
            reglas: {
                contrasena: {
                    required: true,
                    min: 8,
                    max: 200,
                    regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*.?\-_\/&:,;{}+*'¿¡!]?)([A-Za-z\d@$!.%*?\-_\/&:,;{}+*'¿¡!]){8,200}$/
                }

            },
            errores: {
                error: false,
                codigo: false,
                noExisteUsuario: false,
                tipoUsuario: false,
                mensajes: {
                    'U001': 'El correo electrónico o el teléfono es erróneo. Verifique e intente nuevamente',
                    'C002': 'El código suministrado es incorrecto. Verifique e intente nuevamente',
                    'UT001': 'El tipo del usuario es inválido eres admin',
                    'UT002': 'El tipo del usuario es inválido eres leechero',
                    'UT003': 'El tipo del usuario es inválido eres cliente',
                }
            },
            timer: null,
            interval: null,
            timeOut: false,
            segundos: segundosDefault
        }
    },
    methods: {
        toggle(){
            this.limpiar();
            this.copiarUsuario();
            this.$refs.RecuperarContrasena.toggle();
        },
        limpiar(){
            this.payload = {
                usuario: '',
                codigo: [],
                password: '',
                validacionPassword: ''
            }

            this.$refs.validacion.reset();
        },
        copiarUsuario(){
            this.payload.usuario = this.usuario
        },
        avanzarVerificacionCodigo(){
            this.errores.codigo = false
            this.$refs.CodigoSeguridad.toggle();
            this.$refs.RecuperarContrasena.toggle();
        },
        avanzarCambiarContrasena(){
            this.$refs.NuevaContrasena.toggle();
            this.$refs.validadorContrasena.reset();
            this.$refs.CodigoSeguridad.toggle();
        },
        async generarCodigoRecuperacion(esInvalido, avanzar = true){
            // if (esInvalido) return;

            if(!esInvalido && !avanzar){
                if (this.interval || this.timer){
                    return
                }
                this.interval = setInterval(() => {
                    this.segundos--;
                }, 1000)
                this.timer = setTimeout(() => {
                    this.timeOut = false
                    this.segundos = segundosDefault
                    clearTimeout(this.timer)
                    clearInterval(this.interval)
                    this.timer = null
                    this.interval = null
                }, segundosDefault * 1000);

                this.timeOut = true
            }

            try {
                const payload = {
                    usuario: this.payload.usuario,
                    tipo: this.tipoLogin
                };
                const { data } = await ServicioApi.generarCodigoRecuperacion(payload);
                this.notificacion(
                    'Código enviado',
                    'El código de recuperación ha sido enviado al teléfono y/o correo asociados a tu cuenta',
                    'success'
                );
                if (avanzar) this.avanzarVerificacionCodigo();
            } catch (error){
                // this.error_catch(error);
                console.log('epa');
                this.capturarError(error);
            }
        },
        async validarCodigoRecuperacion(){
            if (this.payload.codigo.length !== 5) return;

            try {
                const payload = {
                    usuario: this.payload.usuario,
                    codigo: this.payload.codigo.reduce((string, x) => `${string}${x}`, '')
                };

                const { data } = await ServicioApi.validarCodigoRecuperacion(payload);
                this.notificacion('Verificado', 'Código ha sido verificado con éxito', 'success');
                this.avanzarCambiarContrasena();
            } catch (error){
                this.capturarError(error)
            }
        },
        async restablecerContrasena(){
            try {
                const payload = {
                    usuario: this.payload.usuario,
                    password: this.payload.password
                };
                const { data } = await ServicioApi.restablecerContrasena(payload);
                this.notificacion(
                    'Contraseña actualizada',
                    'La contraseña ha sido actualizada con éxito, por favor intenta ingresar nuevamente',
                    'success'
                );
                this.$refs.NuevaContrasena.toggle();
            } catch (error){
                this.error_catch(error)
            }
        },
        capturarError(error){
            const codigo = error?.response?.data?.codigo;

            if (!!!codigo){
                this.error_catch(error);
                return;
            }

            this.notificacion('Verifique los datos', this.errores.mensajes[codigo], 'error');
            this.errores.error = codigo;
            this.errores.codigo = (codigo === 'C002');
            this.errores.noExisteUsuario = (codigo === 'U001');
            this.errores.tipoUsuario = (codigo === 'UT003' || codigo === 'UT001' || codigo === 'UT002');
        },
        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },
        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.alert-message{
    box-shadow: 0px 1px 4px #00000014;
    width: 338px;
    height: 138px;
    &::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 38px;
        bottom: auto;
        border: 25px solid;
        border-color: transparent transparent transparent white;
    }
}
.text-green{
    color: #28D07B !important;
}
</style>
